/*
--black-color
--darkBlue-color
--lighBlue-color
--orange-color
--white-color
 */


#Stack{
    width: 100%;
    height: 20%;
    background: linear-gradient(var(--darkBlue-color),var(--black-color));
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    overflow: hidden;
}


.StackEnTete{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#StackTitle{
    margin: 5rem;
    font-size: 3rem;
}

.Projects .StackLine{
    width: 12rem;
    height: 0.3vh;
    transform: translateX(600%);
    animation: lineSlideIn 0.8s forwards cubic-bezier(0.33, 1, 0.68, 1);
    color: orange;
    margin:0.5rem;
}


.ReactIcon{
    background-image: url('../media//images/React-Icon.png');
    animation: cloudAnimation 3s ease-in-out infinite;
    position: relative;
}

.SQLIcon{
    background-image: url('../media//images/MySQL-Icon.png');
    animation: cloudAnimation 3.2s ease-in-out infinite;
    position: relative;
}

.SymfonyIcon{
    background-image: url('../media//images/Symfony-Icon.png');
    animation: cloudAnimation 4.3s ease-in-out infinite;
    position: relative;
}
.DockerIcon{
    background-image: url('../media//images/docker.png');
    animation: cloudAnimation 3.5s ease-in-out infinite;
    position: relative;
}

.GitIcon{
    background-image: url('../media//images/gitIcon.png');
    animation: cloudAnimation 4.6s ease-in-out infinite;
    position: relative;
}

.C{
    background-image: url('../media//images/C.png');
    animation: cloudAnimation 3.4s ease-in-out infinite;
    position: relative;
}

.CC{
    background-image: url('../media//images/CC.png');
    animation: cloudAnimation 4s ease-in-out infinite;
    position: relative;
}

.ROS2{
    background-image: url('../media//images/ros.png');
    animation: cloudAnimation 3.7s ease-in-out infinite;
}

.TailwindIcon{
    background-image: url('../media//images/tailwind.png');
    animation: cloudAnimation 3.9s ease-in-out infinite;
    position: relative;
}

.Logos{
    transition: all 0.3s ease;
}


.Logos:hover{
    transform: scale(1.1);
}


.Logos{
    height: 6rem;
    width:6rem;
    border-radius: 40px;
    transition: all 0.3s ease;
    margin:2rem;
}

.Logo{
    height: 100%;
    width:100%;
    background-color: white;
    border-radius: 40px;
    box-shadow: 8px 8px 20px 8px rgba(224, 223, 223, 0.2);
    transition: all 0.3s ease;
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
}

.Logo:hover {
    animation-play-state: paused;
}


.Technologies{
    width: 100%;
    height:70%;
    display: flex;
    justify-content: space-evenly;
    align-items: end;
}

.DevWeb{
    width: 40%;
    height:70%;
    position: relative;
    display: flex;
    flex-direction: column;
    /* background-color: rgba(0,0,0,0.2); */
    border-radius: 1rem;
    padding: 1rem;
}

.Systeme{
    width: 40%;
    height:70%;
    position: relative;
    display: flex;
    flex-direction: column;
    /* background-color: rgba(0,0,0,0.2); */
    border-radius: 1rem;
    padding: 1rem;
}

.TechTitle{
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TechLogos{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


.DevWeb.active{
    animation: popDevWeb 0.6s ease-in;
}

.Systeme.active{
    animation: popSystem 0.6s ease-in;
}

@keyframes popSystem {

    0%{
        opacity: 0;
        transform: translateX(200px);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes popDevWeb {

    0%{
        opacity: 0;
        transform: translateX(-200px);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}







@keyframes cloudAnimation {
    0%{
        transform: translateY(-10px);
    }
    50%{
        transform: translateY(20px);
        transform: translateX(-5px);

    }
    100%{
        transform: translateY(-10px);
    }
}


@media screen and (max-width: 1400px){

    .Logos{
        height: 5rem;
        width:5rem;
        border-radius: 0px;
    }

}

@media screen and (max-width: 900px){

    .Logos{
        height: 4rem;
        width:4rem;
        border-radius: 0px;
    }

    .Technologies{
        width: 100%;
        height:90%;
        display: flex;
        flex-direction: column;
    }

    .DevWeb{
        width: 100%;
        height:40%;
        margin-bottom: 1rem;
    }
    .Systeme{
        margin-bottom: 1rem;
        width: 100%;
        height:40%;
    }
}

@media screen and (max-width: 500px){
    #Stack{
        height: 30%;
    }

    .StackTitle{
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .Logos{
        height: 4rem;
        width:4rem;
        border-radius: 0px;
    }

    .Technologies{
        width: 100%;
        height:90%;
        display: flex;
        flex-direction: column;
    }

    .DevWeb{
        width: 100%;
        height:40%;
        margin-bottom: 1rem;
    }
    .Systeme{
        margin-bottom: 1rem;
        width: 100%;
        height:60%;
    }
}

@media screen and (max-width: 380px){
    #Stack{
        height: 30%;
    }

    .Logos{
        height: 4rem;
        width:4rem;
        border-radius: 0px;
        margin: 1rem;
    }

    .Technologies{
        width: 100%;
        height:90%;
        display: flex;
        flex-direction: column;
    }

    .DevWeb{
        width: 100%;
        height:40%;
        margin-bottom: 1rem;
    }
    .Systeme{
        margin-bottom: 1rem;
        width: 100%;
        height:60%;
    }
}