/*
--black-color
--darkBlue-color
--lighBlue-color
--orange-color
--white-color
 */


.About{
    width: 100%;
    height: 25%;
    background-color: var(--white-color);
    position: relative;
    overflow: hidden;
    background-color: #f0f0f0;
    display: flex;
}

.left-side {
    padding: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    height: 100%;
    background-color: #000000;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
}

.right-side{
    position: absolute;
    right: 0px;
    width: 38%;
    background-image: url('../media/images/andrew2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -40px;
    height: 100%;
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
    transform: translateY(0);
}




.DownloadCV {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 200px;
    border: 1px solid transparent;
    animation: linkRiseUp 0.6s forwards 0.9s;
    opacity: 0;
    margin-top: 0.3rem;
    transition: all 0.1s;
  }

.DownloadCV:hover{
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid white;
    color: white;
  }

  .left-side .link {
    text-decoration: none;
    color: black;
    background-color: #f0f0f0;
    border: none;
    margin: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 200px;
    border: 1px solid transparent;
    animation: linkRiseUp 0.6s forwards 0.9s;
    opacity: 0;
    margin-top: 0.3rem;
    transition: all 0.1s;
    padding: 10px;
  }

  .left-side   .link:hover{
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid white;
    color: white;
  }


.AboutOne{
    width: 85%;
}



.Block h1{
    margin: 1vw;
    opacity: 1;
    font-size: 3rem;
    margin: 1.5rem;
}

.Block p{
    font-size: 1.5rem;
}




.Block h1.active{
    animation: textRiseUp 1s ease-in;
}

.Block p.active{
    animation: textRiseUp 1.3s ease-in;
}

.right-side.active{
    animation: AppearingPhoto 1.4s ease-in-out;
}

@keyframes AppearingPhoto {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
}




@keyframes textRiseUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@media screen and (max-width: 1200px){
    .right-side{
        background-position: -120px;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 4px rgba(255, 255, 255, 0.3);
    }
}



@media screen and (max-width: 800px){
    .left-side,
    .right-side {
        clip-path: none;
    }

    .About{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000000;
        height: 30%;
    }
    .left-side {
        width: 100%;
        position: static;
    }

    .right-side{
        width: 80%;
        height: 79%;
        position: static;
        background-position: 0px;
        margin-bottom: 10rem;
    }


    .Block h1{
        opacity: 1;
        font-size: 1rem;
    }

    .Block p{
        padding: 1rem;
        font-size: 1rem;
    }

}
@media screen and (max-width: 480px){
    .right-side{
        background-position:10%;
    }
}


@media screen and (max-width: 450px){
    .right-side{
        background-position:15%;
    }
}
