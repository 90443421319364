/*
--black-color
--darkBlue-color
--lighBlue-color
--orange-color
--white-color
 */


.App{
  width: 100%;
  height: 400%;
}



