/*
--black-color
--darkBlue-color
--lighBlue-color
--orange-color
--white-color
 */


#Footer {
    width: 100%;
    height: 21%;
    position: relative;
    background-image: url('../media/images/moon.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: rotate(180deg);
}

#Footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, var(--black-color), rgba(0, 0, 0, 0.4));
    pointer-events: none;
    z-index: 1;
}

#Footer > * {
    position: relative;
    z-index: 2;
    color: white;
}



.Footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
    position: relative;
  }

  .footer-content {
    width: 100%;
    height: 100% ;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    transform: rotate(180deg);
  }



.CopyRight {
    width: 100%;
    height: 40%;
    color: #aaa;
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    display: flex;
    justify-content: center;
    align-items: end;
    position: absolute;
    bottom: 0rem;
  }
  

.ContactSection h2{
  width: 100%;
  height: 40%;
  margin-bottom: 2rem;
 
}

.ContactSection{
  height:20%;
  width:100%;
}

.footer-content h1{
  display: flex;
  justify-content: center;
  align-items: end;
  width:100%;
  height: 20%;
  display: flex;
}




