/*
--black-color
--darkBlue-color
--lighBlue-color
--orange-color
--white-color
 */


 #Header{
    width: 100%;
    height: 25%;
    background-image: url('../media//images/hole.png');
    background-size: cover;
    background-repeat: no-repeat;
}


/*************************************/
/***************NAVBAR****************/
/*************************************/

#NavBar{
    width: 100%;
    height: 10%;
    top: 0%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vw;
    z-index: 1000;
    margin-top: 10px;
}



/**********************/
/*********LOGO*********/
/**********************/


#Logo {
    width: 17vw;
    height: 14vh;
    text-align: center;
    background-image: url('../media/images/Andrew.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    animation: AppearingLogo 1.7s ease-in-out;
    position: relative;
}

@keyframes AppearingLogo {
    0% {
        opacity: 0;
        transform: translateY(-200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}


/************************/
/*******NAVIGATION*******/
/************************/

#Navigation{
    width: 53vw;
    height: 5vh;
    border-radius: 20px;
    position: fixed;
    transform: translateX(50%);
    right: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    animation: Appearing 1s ease-in-out;

}

#NavigationPhone {
    width: 50vw;
    height: 7vh;
    border-radius: 50px;
    position: fixed;
    transform: translateX(50%);
    right: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    bottom: 3rem;
    background-color: rgba(36, 25, 25, 0.7);
    backdrop-filter: blur(10px);
    margin: 1vw;
}

#NavigationPhone Button{
    width: 6vw;
    height: 6vw;
    border-radius: 100px;
    padding: 1vw;
    background-color: transparent;
    cursor: pointer;
}

#NavigationPhone Button div{
    background-size: contain;
    background-position: center;
    background-color: transparent;
    width: 100%;
    height: 100%;
}

#NavigationPhone .currentSection {
    animation: GetSelected 1s forwards;

}

@keyframes GetSelected {
    0%{

    }
    100%{
        background-color: var(--lighBlue-color);
    }
}


#HomeButton{
    background-image: url('../media/images/home.png');
    cursor: pointer;
}

#ContactButton{
    background-image: url('../media/images/contact.png');
    cursor: pointer;
}

#SkillsButton{
    background-image: url('../media/images/roue.png');
    cursor: pointer;
}

#ProjectsButton{
    background-image: url('../media/images/book.png');
    cursor: pointer;
}

#AboutButton{
    background-image: url('../media/images/person.png');
    cursor: pointer;
}

#Navigation button{
    background-color: transparent;
    color: var(--white-color);
    width: 12vw;
    height: 6vh;
    font-family: 'Abang';
    border-bottom: 1px  solid rgba(255, 255, 255, 0);
    transition: all 0.3s ease-in-out; /* Smooth transition */
    position: relative;
    cursor: pointer;
    font-size: 1rem;
}

#Navigation button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: rgba(255, 255, 255, 1);
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

@keyframes Appearing {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

#Navigation button:hover::after{
    width: 100%;
    left: 0;
}

#Navigation .currentSection::after {
    box-shadow: 0px 0px 7px 1px rgb(255, 255, 255);
    width: 100%;
    left: 0;
}

/************************/
/*********DISPLAY********/
/************************/



#DisplayMode{
    width: 8vw;
    height: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#DisplayMode div{
    width: 4vw;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
}




/********************************************/
/***************PRESENTATION*****************/
/********************************************/


#Presentation{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Header .TitleContainer {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.Header .TitleContainer .Line {
    width: 14%;
    height: 0.3vh;
    transform: translateX(600%);
    animation: lineSlideIn 0.8s forwards cubic-bezier(0.33, 1, 0.68, 1);
}

.Header .TitleContainer .Title,
.Header .TitleContainer .Text {
    overflow: hidden;
    margin-top: 1vh;
    margin-bottom: 2vh;
    color: var(--white-color);
}

.Header .TitleContainer .Title h1 {
    font-weight: bold;
    transform: translateX(-50%);
    opacity: 0;
    animation: textRiseUp 0.6s forwards 0.5s;
    font-size: 4rem;
}

.Header .TitleContainer .Text p {
    width: 80%;
    transform: translateX(-50%);
    opacity: 0;
    animation: textRiseUp 0.6s forwards 0.9s;
    font-size: 1.6rem;
}


.link {
    background-color: #f0f0f0;
    border: none;
    margin: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 200px;
    border: 1px solid transparent;
    animation: linkRiseUp 0.6s forwards 0.9s;
    opacity: 0;
    margin-top: 0.3rem;
    transition: all 0.1s;
  }

  .link:hover{
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid white;
    color: white;
  }

  .link a {
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 10px 20px;
}

  .icon {
    margin-right: 8px;
  }




@keyframes lineSlideIn {
    0% {
        transform: translateX(600%);
    }
    100% {
        transform: translateX(0);
        background-color: rgb(240, 102, 16);
    }
}

@keyframes textRiseUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes linkRiseUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 800px){
    #Logo{
        width: 27vw;
        height: 24vh;
    }
    .Header .TitleContainer .Text p {
        width: 90%;
    }
    .Header .TitleContainer .Title h1 {
        font-size: 3rem;
    }
    .Header .TitleContainer .Text p{
        font-size: 1.5rem;
    }
}


@media screen and (max-width: 560px){
    #Logo{
        width: 37vw;
        height: 34vh;
    }
    .Header .TitleContainer .Text p {
        width: 95%;
    }

    .Header .TitleContainer .Title h1 {
        font-size: 2rem;
    }
    .Header .TitleContainer .Text p{
        font-size: 1.2rem;
    }
}

