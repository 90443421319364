/*
--black-color
--darkBlue-color
--lighBlue-color
--orange-color
--white-color
 */

 #Projects {
    width: 100%;
    height: 25%;
    background-image: linear-gradient(
        180deg,
        hsl(0, 0%, 0%) 0%,
        var(--darkBlue-color) 94%
      );    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

}

#TitleProjects {
    font-size: 2rem;
    color: var(--white-color);
    margin-top: 2rem;
}

#SelectedProjects {
    width: 90%;
    height: 80%;
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.Project {
    width: 23rem;
    height: 25rem;
    border-radius: 7px;
    overflow: hidden;
    margin: 0.5rem;
    position: relative;
}

.Project.active{
    animation: projectAppear 0.8s ease-in;

}

@keyframes projectAppear {
        0% {
            opacity: 0;
            transform: translateY(100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
}

.ProjectImg {
    width: 100%;
    height: 70%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.5s;
}

.EPH{
    background-image: url('../media/images/eph.png');
}

.ROVER{
    background-image: url('../media/images/rover.png');
}

.CMS{
    background-image: url('../media/images/gap.png');
    background-size: contain;
    background-color: white;
}

.ProjectAnimation {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    opacity: 0;
    transform: scale(130%);
    transition: all 0.5s;
}

.ProjectInfos {
    width: 100%;
    height: 30%;
    background-color: rgb(0, 0, 0);
    padding: 1rem;
    position: relative;
}

.ProjectTitle {
    margin-bottom: 0.3rem;
    color: var(--white-color);
}

.ProjectText {
    color: rgb(140, 137, 137);
    overflow: hidden;
    position: absolute;
    left: 1rem;
}

.ProjectShow {
    color: rgb(140, 137, 137);
    overflow: hidden;
    position: absolute;
    left: 1rem;
}

.ProjectText span {
    display: inline-block;
    transition: all 0.5s;
}

.ProjectShow span {
    display: inline-block;
    transform: translateY(2rem);
    transition: all 0.5s;
}


.ProjectDetailsOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Fond noir semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Assure que l'overlay est au-dessus de tout le reste */
    opacity:1;
    animation: popProject 0.3s ease;
}




@keyframes popProject {
    0%{
        opacity: 0;

    }
    100%{
        opacity: 1;
    }
}




/* Nouveaux styles pour Project */

.ProjectDetailsContainer {
    background-color: white;
    padding: 20px;
    width: 60rem;
    height: 40rem;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    animation: popProject 0.3s ease;
    opacity: 1;
    z-index: 1001;
    pointer-events: auto;
    color: black;
    display: flex;
    justify-content: center;
    align-content: center;
}

.ProjectDetailsContainerLeft{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ProjectDetailsContainerRight{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.PartOne{
    width: 100%;
    height: 31%;

}

.PartTwo{
    width: 100%;
    height: 31%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}

.PartThree{
    width: 100%;
    height: 31%;
    position: relative;
}


.ProjectDetailsTitle {
    font-size: 2rem;
    font-family: 'Abang';
    margin-top: 4rem;
    margin-left: 2rem;
}



.ProjectDetailsDescription {
    margin-left: 2rem;
}

.ProjectDetailsClient{
    margin-left: 2rem;

}

.LinkProject{
    width: 7rem;
    height: 2.9rem;
    position: absolute;
    left: 2rem;
    bottom: 3rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--black-color);
    border: 1px solid transparent;
    font-size: 1rem;
    color: white;
    text-decoration: none;
    border-radius: 70px;
    transition: all 0.3s ease;
}

.LinkProject:hover{
    background-color: var(--white-color);
    border: 1px solid black;
    color: black;
}

.StackLine{
    width: 7rem;
    height: 0.3vh;
    transform: translateX(600%);
    animation: lineSlideIn 0.8s forwards cubic-bezier(0.33, 1, 0.68, 1);
    color: orange;
    margin:0.5rem;
}

.ProjectDetailsVideo{
    margin: 1rem;
    height: 50%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black-color);
    box-shadow: 0px 0px 5px 1px black;
}

.Frame{
    width: 98%;
    height: 98%;

}


.ProjectDetailsStack {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ProjectDetailsStack ul {
    width: 70%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    padding: 0;
  }

  .ProjectDetailsStack li {
    width: 5rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black-color);
    font-size: 1rem;
    color: var(--white-color);
    border-radius: 200px;

  }

  .ProjectDetailsLink a {
    text-decoration: none;
    color: var(--darkBlue-color);
    font-weight: bold;
    transition: color 0.3s ease;
  }





/**/

/**************ANIMATION******************/

.Project:hover{
    cursor: pointer;
}
.Project:hover .ProjectImg {
    transform: scale(1.1);
}

.Project:hover .ProjectAnimation {
    opacity: 0.8;
    transform: scale(80%);
}

.Project:hover .ProjectText span {
    opacity: 0;
    transform: translateX(-3rem);
}

.Project:hover .ProjectShow span {
    opacity: 1;
    transform: translateY(0rem);}


/**************FRAMES******************/


@media screen and (max-width: 1300px){

    #Projects {
        height: 20%;
    }

    .Project{
        width: 20rem;
        height:20rem;
    }

    .ProjectInfos{
        padding:0.6rem;
        padding-left: 1rem;
    }

    .ProjectTitle{
        margin-bottom: 0.2rem;
        font-size: 1.3rem;
    }
}




@media screen and (max-height: 750px){
    .Project{
        height: 18rem;
        width: 20rem;
    }
}

@media screen and (max-height: 550px){
    .Project{
        height: 16rem;
        width: 18rem;
    }
}




@media screen and (max-width: 800px){
    #Projects{
        height: 20%;
    }
}

@media screen and (max-width: 450px){
    #Projects{
        height: 20%;
    }
    #TitleProjects{
        font-size: 1.4rem;
    }

}



@media screen and (max-width: 1000px){

    .ProjectDetailsContainer{
        width: 50rem;
        height: 30rem;
    }
    
    #Projects {
        height: 25%;
    }
}

@media screen and (max-width: 850px){

    .ProjectDetailsContainer{
        width: 30rem;
        height: 50rem;
        display: flex;
        flex-direction: column;
    }

    .ProjectDetailsTitle{
        margin-top: 1rem;
    }


    .ProjectDetailsContainerLeft{
        width: 100%;
        height: 55%;
    }

    .ProjectDetailsContainerRight{
        width: 100%;
        height: 45%;
    }
   

    .ParOne{
        height: 10%;
    }
    .PartTwo{

        height: 60%;

    }
    .PartThree{
        height: 40%;
    }
}


@media screen and (max-width: 550px){

    .ProjectDetailsContainer{
        width: 20rem;
        height: 50rem;
        display: flex;
        flex-direction: column;
    }

    .StackTtitle{
        font-size:1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .TechTitle h2{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
    }

    .TechTitle{
        font-size:1.2rem;

    }
    .ParOne{
        height: 10%;
    }
    .PartTwo{

        height: 60%;

    }
    .PartThree{
        height: 70%;
        bottom: 0rem;
    }
   


    .ProjectDetailsContainerLeft{
        width: 100%;
        height: 55%;
    }

    .ProjectDetailsContainerRight{
        width: 100%;
        height: 45%;
    }
   


}


























